import React from 'react'
import { Link, Typography } from '@material-ui/core'

const LanguageSwitch = ({ value, options, onSelect, classes }) => {
  return (
    <Typography className={classes.root}>
      {Object.keys(options).map((key, i) => {
        const lang = (value === undefined && i === 0) || key === value
          ? <span className={classes.selected}>{options[key]}</span>
          : (
            <Link
              onClick={(e) => {
                e.preventDefault()
                onSelect && onSelect(key)
              }}
              className={classes.lang}
              href={'#'}
            >
              {options[key]}
            </Link>
          )
        return (
          <span key={i.toString()}>{i !== 0 && ' / '}{lang}</span>
        )
      })}
    </Typography>
  )
}

export default LanguageSwitch
