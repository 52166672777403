import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import Component from './component';
import BigNumber from 'bignumber.js';
import RandomColor from 'randomcolor';
import useSetColors from '../helpers/useSetColors';

const GameStatsByUnitContainer = ({ data, unit }) => {
  const [gameData, setGameData] = useState({});
  const [COLORS, addColor] = useSetColors();

  useEffect(() => {
    prepareGameData(data);
  }, data);

  const prepareGameData = ({ game, groups }) => {
    let preparedParticipants = _flatten(
      groups.map((group, index) => {
        addColor(
          RandomColor({
            luminosity: 'light',
            hue: 'random',
            seed: `${group.name}${group._id}${group.participants.length}`,
          })
        );
        return group.participants.map(participant => {
          return {
            ...participant,
            x: participant[unit],
            yOffset: 10,
            xOffset: -5,
            color: index,
            label: `${participant.user.firstName} ${participant.user.lastName} (${new BigNumber(participant[unit])
              .toFixed(2)
              .toString()})`,
          };
        });
      })
    );
    preparedParticipants = preparedParticipants
      .sort((a, b) => a[unit] - b[unit])
      .map((participant, index) => ({
        ...participant,
        y: index,
      }));
    setGameData({
      game,
      participants: preparedParticipants,
    });
  };

  if (_isEmpty(gameData)) return 'Loading...';
  return (
    <>
      <Component data={gameData} colors={COLORS} />
    </>
  );
};

GameStatsByUnitContainer.propTypes = {
  data: PropTypes.object,
  unit: PropTypes.oneOf(['lepts', 'merits']),
};

export default GameStatsByUnitContainer;
