import React, { Component } from 'react';
import classNames from 'classnames';
import { Portals } from 'portals';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { theme } from './styles/theme';
import Typography from '@material-ui/core/Typography';
import { withCookies } from 'react-cookie';
import { red } from '@material-ui/core/colors';

import bcrypt from 'bcryptjs';
import UIScope from './UIScope';
import LanguageSwitch from '../../components/LanguageSwitch'

const {
  Portal,
  WebSocketChannel,
  //AxiosChannel,
  UI,
  LogModule,
  fixValue,
} = Portals;

const CONNECTED_PORTALS_BY_ENDPOINT = {};

const styles = ({ palette, spacing: { unit } }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100hw',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    minHeight: 0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: unit * 2,
    boxSizing: 'border-box',
  },
  wrapper2: {
    display: 'flex',
    maxHeight: '100%',
    '&>*:not(:last-child)': {
      marginRight: unit * 2,
    },
  },
  scope: {
    display: 'flex',
    minHeight: 0,
    flexDirection: 'column',
  },
  paper: {
    // width: '100%',
    minWidth: unit * 40,
    minHeight: 0,
    maxHeight: '100%',
    padding: unit * 2,
    borderRadius: unit * 2,
    boxSizing: 'border-box',
    boxShadow: '10px 25px 30px rgba(0, 0, 0, .25)',
    display: 'flex',
    flexDirection: 'column',
  },
  square: {
    borderRadius: 0,
  },
  paperTitle: {
    textAlign: 'left',
    marginBottom: unit * 2,
  },
  endpoint: {
    width: '100%',
    marginBottom: unit * 2,
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    paddingLeft: `${unit * 2}px !important`,
    paddingRight: `${unit * 2}px !important`,
  },
  buttonImportant: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      color: red[500],
      borderColor: red[500],
      backgroundColor: red[50],
    },
  },
  buttonsContainerWrapper: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  buttonsContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: unit,
    },
  },
  logo: {
    fontFamily: 'meritodynamics-icons',
  },
  logoSmall: {
    height: unit * 3,
    marginBottom: unit * 2,
  },
  rightIcon: {
    paddingLeft: unit * 2,
    marginLeft: 'auto',
  },
  input: {
    marginTop: unit,
  },
  invisible: {
    display: 'none',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3,
    transition: 'opacity 500ms',
    transitionDelay: '250ms',
  },
  header: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // textAlign: 'left',
    padding: unit * 2,
    paddingTop: unit,
    paddingBottom: 0,
  },
  footer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    padding: unit * 2,
    paddingTop: 0,
  },
  checkbox: {
    marginLeft: 0,
    '&+label': {
      marginTop: '-16px !important',
    },
  },
  radioGroupFormControl: {
    marginLeft: unit,
  },
  description: {
    maxWidth: 320,
    marginLeft: unit,
    marginRight: unit,
    marginBottom: unit * 2,
    opacity: 0.5,
  },
  fullPage: {
    width: '100%',
    height: '100%',
  },
  langSwitch: {
    display: 'unset',
    color: 'lightgrey',
    marginLeft: 'auto',
  },
  lang: {
    color: 'lightgrey',
    '&:hover': {
      color: '#F7CC44',
      textDecoration: 'none',
    }
  },
  langSelected: {
    color: 'black'
  }
});

class PortalsUI extends Component {
  state = {};
  _pendingPromises = [];

  constructor(props) {
    super(props);
    const { endpoint } = props;
    this._initWebsocketPortal(endpoint);
  }

  componentWillUnmount() {
    //
  }

  _initWebsocketPortal = async endpoint => {
    let portal = CONNECTED_PORTALS_BY_ENDPOINT[endpoint];
    let backendEnv;
    if (!portal) {
      portal = await new Portal('frontend-' + Math.random().toString()).setChannel(new WebSocketChannel(endpoint));
      // .setChannel(new AxiosChannel('http://localhost:3008/portal'))

      try {
        await portal.connectTo('backend');
      } catch (e) {
        const { onError } = this.props;
        onError && onError();
        return;
      }

      CONNECTED_PORTALS_BY_ENDPOINT[endpoint] = portal;
    }

    backendEnv = portal.getEnv('backend');

    // uiObject = UI().fromObject(interFace).compile().value

    if (!backendEnv) {
      this.setState({ portalStatus: 'No backendEnv received' });
      return;
    }

    this.setState({ isConnected: true, env: backendEnv });
    await backendEnv.setUpdateMethod(this._handleExternalUpdate);

    this._update();
  };

  _queuePendingResolution = async () => {
    let resolvePending;
    let promise = new Promise(resolve => {
      resolvePending = () => {
        const index = this._pendingPromises.indexOf(promise);
        if (index !== -1) this._pendingPromises.splice(index, 1);
        resolve();
      };
    });
    this._pendingPromises.push(promise);
    await this._getPendingPromises(true);
    return resolvePending;
  };

  _getPendingPromises = async skipLast => {
    let promises = [...this._pendingPromises];
    if (skipLast) promises.pop();
    return promises.length ? Promise.all(promises) : true;
  };

  _handleExternalUpdate = async (scopeIds = []) => {
    for (const scopeId of scopeIds) {
      await (async () => {
        await this._getPendingPromises();

        let ui = [...this.state.ui];
        let scopes = [];

        for (const uiItem of ui) {
          scopes.push(uiItem && uiItem.id);
        }

        const [name, id, action] = scopeId.split(':');
        if (scopes.indexOf(name) === -1) return;

        if (action === 'close') {
          let ui = [...(this.state.ui || [])];
          for (let i = 0; i < ui.length; i++) {
            const { id: uiId } = ui[i];
            if (uiId === name) {
              ui = ui.slice(0, i);
              console.log('PortalsUI._handleExternalUpdate remove >>>', name);
              await new Promise(resolve => this.setState({ ui }, resolve));
              break;
            }
          }
          return;
        }

        const scope = {};
        for (const uiItem of ui) {
          if (!uiItem || !uiItem.id) continue;
          const currentScope = (scope[uiItem.id] = {});
          (uiItem.value || []).forEach(
            item =>
              item && item.id && (currentScope[item.id] = item.value !== undefined ? item.value : item.defaultValue)
          );
          if (uiItem.id === name) break;
        }

        console.log('PortalsUI._handleExternalUpdate update >>>', name);
        this._update(name, { ...scope, id }, undefined, true);
        // await new Promise((resolve) => setTimeout(resolve, 3000))
      })();
    }
  };

  _handleChange = async (id, data = {}) => {
    const { cookies } = this.props;
    data.$cookies = cookies.get('portal');
    let { ui = [] } = this.state;
    const level = ui.length;

    let scope = {};
    ui.slice(0, level + 1).forEach(uiItem => {
      if (!uiItem || !uiItem.id) return;
      const currentScope = (scope[uiItem.id] = {});
      (uiItem.value || []).forEach(
        item => item && item.id && (currentScope[item.id] = item.value !== undefined ? item.value : item.defaultValue)
      );
    });
    return this._update(id, { ...scope, ...data });
  };

  _handleBackClick = (level = -1) => {
    let { ui = [] } = this.state;
    ui = ui.slice(0, level);

    ui.map(uiItem => (uiItem.__key = Math.random().toString()));

    this.setState({ ui });
  };

  _handleButtonClick = async (item, level) => {
    const { props = {} } = item || {};
    if (props.keepUrl !== true) {
      const { history, location } = this.props;
      location.pathname !== '/' && history.push('/', { silent: true });
    }

    await this._appendUi(item.id, level, item.calls, item.reveals);
  };

  componentDidUpdate(prevProps, prevState) {
    const { pathname, state: { silent } = {} } = this.props.location;
    const { pathname: prevPathname } = prevProps.location;

    if (pathname !== prevPathname) {
      !silent && this.setState({ ui: undefined }, this._update);
    }
  }

  _appendUi = async (id, level, calls, reveals) => {
    let { ui } = this.state;
    if (level === undefined || level === -1) level = ui.length;

    let scope = {},
      scopeUrl = '';
    for (const uiItem of ui.slice(0, level + 1)) {
      if (!uiItem.id) continue;
      const currentScope = (scope[uiItem.id] = {});
      for (const item of uiItem.value || []) {
        if (item.value && item.type === 'password') {
          item.value = await new Promise((resolve, reject) => {
            // bcrypt.genSalt(10, (err, salt) => {
            bcrypt.hash(item.value, '$2a$10$mDGREOwi7JN/GaZLlWzVh.', (err, hash) => {
              if (err) return reject(err);
              resolve(hash);
            });
            // })
          });
        }
        item.id && (currentScope[item.id] = item.value !== undefined ? item.value : item.defaultValue);
      }
      scopeUrl += '/' + uiItem.id;
      currentScope._id && (scopeUrl += ':' + currentScope._id);
    }

    calls && (scopeUrl += '/' + calls);
    id && (scopeUrl += ':' + id);

    if (reveals) {
      if (level !== undefined) ui = ui.slice(0, level + 1);
      return this.setState({
        ui: [...ui, { ...reveals, __key: Math.random().toString() }],
      });
    }

    id && (scope.id = id);

    // this.setState({ ui }, () =>
    await this._update(calls, scope, level !== undefined ? level + 1 : undefined);
    // );
  };

  _update = async (id, scope = {}, level, update) => {
    const resolvePending = await this._queuePendingResolution();

    const { cookies, location } = this.props;
    let { ui = [] } = this.state;

    const { env } = this.state;

    const urlParams = {};

    if (!id) {
      const [locId, locParamId] = location.pathname.split('/')[1].split(':');
      id = locId;
      locParamId && (urlParams.id = locParamId);
    }

    location.search &&
      location.search
        .split('?')[1]
        .split('&')
        .forEach(param => {
          const [key, value] = param.split('=');
          urlParams[key] = value;
        });

    scope = { ...scope, ...urlParams };

    level && (ui = ui.slice(0, level));

    try {
      scope.$cookies = cookies.get('portal');

      let newData;

      try {
        this.setState({ currentUpdateId: id });
        newData = await env.get(id + (update ? ':update' : ''), scope);
      } catch (e) {
        throw process.env.NODE_ENV === 'development' ? e : new Error('Серверная ошибка');
      }

      const lastLevelUi = ui.slice(-1)[0];
      const lastLevelIsWeak = lastLevelUi && (lastLevelUi.props || {}).$weak;
      if (lastLevelIsWeak) ui = ui.slice(0, -1);

      if (!newData) {
        this.setState({ ui }, resolvePending);
        return;
      }
      let { ui: receivedUi = {} } = newData;
      const { $resetUi, $cookies, $redirect, $append, $resetLocation, $fallTo } = newData;

      let resetLocation = $resetLocation;

      if ($cookies) {
        cookies.set('portal', $cookies);
      }
      if ($redirect) {
        this.setState({ ui, isPending: false }, () => {
          this.props.history.push('/' + $redirect.to, { silent: false });
          resolvePending();
        });
        return;
      } else if ($fallTo) {
        let index = 0;
        for (const uiItem of ui) {
          index++;
          if (!uiItem) continue;
          if (uiItem.id === $fallTo) {
            uiItem.__key = Math.random().toString();
            ui = ui.slice(0, index);
            break;
          }
        }
      }
      if ($append) {
        this.setState({ ui, isPending: false }, () => {
          resolvePending();
          const [calls, id] = $append.split(':');
          this._appendUi(id, level, calls);
        });
        return;
      }

      resetLocation && location.pathname !== '/' && this.props.history.push('/', { silent: true });

      if (!Object.keys(receivedUi).length) {
        this.setState({ ui, isPending: false }, resolvePending);
        return;
      }

      if ($resetUi) ui = [];

      let existingUi = ui.find(({ id: existingUiId }) => existingUiId === id);

      if (existingUi && receivedUi.id === existingUi.id) {
        receivedUi.value.forEach((item, index) => {
          if (!item.id) {
            item.__key = Math.random().toString();
            return;
          }
          const existingItem = existingUi.value.find(({ id }) => id === item.id);
          if (existingItem && existingItem.id === item.id) {
            if (existingItem.__originalValue !== undefined) {
              existingItem.__originalValue = item.value;
              receivedUi.value[index] = existingItem;
            } else if (existingItem.value !== item.value) {
              item.__key = Math.random().toString();
            } else {
              item.__key = existingItem.__key || Math.random().toString();
            }
          }
        });
        receivedUi.__key = existingUi.__key;
        // receivedUi.__key = Math.random().toString();
        ui.splice(ui.indexOf(existingUi), 1, receivedUi);
        receivedUi = undefined;
      } else {
        receivedUi.__key = Math.random().toString();
      }

      const newUi = [...ui];
      if (receivedUi) newUi.push(receivedUi);

      this.setState(
        {
          ui: newUi,
          isPending: false,
        },
        resolvePending
      );
    } catch (e) {
      this.setState(
        {
          ui: [
            ...ui,
            {
              name: 'Error/Ошибка',
              value: [
                {
                  value: e.message,
                  name: 'Message/Сообщение',
                  type: 'string',
                  props: { multiline: true, rowsMax: 5 },
                },
                ...(ui.length <= 1 ? [{ name: 'На главную / Main page', type: 'button', calls: 'index' }] : []),
              ],
            },
          ],
          isPending: false,
        },
        resolvePending
      );
    }
  };

  render() {
    const { classes, cookies } = this.props;
    const { isConnected, ui, isPending, env } = this.state;

    const currentLevelProps = ((ui || []).slice(-1)[0] || {}).props || {};
    const { fullPage } = currentLevelProps;

    const isMobile = window.innerWidth <= 500;
    const maxLevelsAtATime = isMobile || fullPage ? 1 : 4;

    // ui && ui.forEach((uiItem, index) => (uiItem.__invisible = isMobile && index < ui.length - 1))

    const viewHeight = isMobile ? window.innerHeight + 'px' : '100vh';

    let showBreadCrumbs;
    let breadCrumbs;
    let invisible;

    const content =
      ui &&
      ui.map((uiItem, index) => {
        showBreadCrumbs = index === ui.length - 1 && index > maxLevelsAtATime - 1;
        breadCrumbs =
          showBreadCrumbs &&
          ui
            .slice(maxLevelsAtATime - 1, -1)
            .map(({ name }) => name)
            .join(' / ');
        invisible =
          ((uiItem.props || {}).displayOnCurrentLevelOnly && index !== ui.length - 1) ||
          (index !== ui.length - 1 && index >= maxLevelsAtATime - 1);
        return (
          <Paper
            className={classNames(classes.paper, {
              [classes.invisible]: invisible,
              [classes.square]: isMobile,
              [classes.fullPage]: fullPage,
            })}
            key={uiItem.__key}
          >
            <UIScope
              env={env}
              classes={classes}
              title={index <= 0 ? uiItem.name : breadCrumbs}
              data={uiItem}
              backButtonIcon={showBreadCrumbs ? 'arrow_back' : index > 0 && 'close'}
              onButtonClick={item => this._handleButtonClick(item, index)}
              onBackClick={() => this._handleBackClick(index)}
              onChange={this._handleChange}
            />
          </Paper>
        );
      });

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classNames(classes.container)} style={{ height: viewHeight }}>
          <div className={classes.header}>
            <a href={'/'} style={{ textDecoration: 'none', verticalAlign: 'middle' }}>
              <Typography variant={'h6'}>
                <span className={classes.logo}>meritodynamics-circle-inverted</span>
                {(cookies.get('portal') || {}).lang === 'ru' ? 'Меритодинамика' : 'Meritodynamics'}
              </Typography>
            </a>
            <LanguageSwitch
              classes={{
                root: classes.langSwitch,
                lang: classes.lang,
                selected: classes.langSelected,
              }}
              options={{ ru: 'рус', en: 'eng' }}
              value={(cookies.get('portal') || {}).lang}
              onSelect={(key) => {
                const portalCookies = cookies.get('portal') || {}
                portalCookies.lang = key
                cookies.set('portal', portalCookies)
                this.setState({ui:undefined});
                this._update()
              }}
            />
          </div>
          <div className={classNames([classes.wrapper, { [classes.disabled]: isPending }])}>
            <div className={classNames([classes.wrapper2, { [classes.fullPage]: fullPage }])}>{content}</div>
          </div>
          <div className={classes.footer}>
            <Typography style={{ marginRight: 10 }}>meritodynamics.com</Typography>
            <Typography style={{ marginLeft: 'auto' }}>2019 © Алгоритм Шеймана</Typography>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

PortalsUI.customComponents = {};
PortalsUI.addCustomComponents = components => {
  Object.assign(PortalsUI.customComponents, components);
};

export default withStyles(styles)(withCookies(PortalsUI));
