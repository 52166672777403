import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import * as ReactDOM from 'react-dom'

export default class UISelect extends React.Component {
  state = {
    labelWidth: 0,
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  _handleChange = ({ target: { value } }) => {
    // console.log('UISelect._handleChange, Line 278: value >', value);
    const { data } = this.props;
    data.value = value === '' ? undefined : value;
    this.setState({});
  }

  render() {
    const { data = {}, classes } = this.props;
    const { id, name, props: { options = [], disabled = false } = {} } = data;
    const value = data.value !== undefined ? data.value : (data.defaultValue || '');
    // console.log('value: ', value);
    return (
      <FormControl
        // margin={'dense'}
        style={{marginTop: 6, marginBottom: 12}}
        variant="outlined"
        // className={classes.formControl}
      >
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="select"
        >
          {name}
        </InputLabel>
        <Select
          value={value}
          onChange={this._handleChange}
          labelWidth={this.state.labelWidth}
          input={
            <OutlinedInput
              // defaultValue={value}
              // labelWidth={this.state.labelWidth}
              id="select"
            />
          }
        >
          <MenuItem value={''}><em> </em></MenuItem>
          {options.map(({label, value})=>(
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
