import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBarSeries, XYPlot, LabelSeries } from 'react-vis';

const GameStatsByUnit = ({ data: { participants }, colors }) => {
  return (
    <XYPlot width={1000} height={participants.length * 24} colorRange={colors}>
      <HorizontalBarSeries className="" data={participants} stack={false} style={{ height: '22px' }} />
      <LabelSeries
        animation
        allowOffsetToBeReversed
        data={participants}
        style={{
          fontFamily: 'Roboto',
        }}
      />
    </XYPlot>
  );
};

GameStatsByUnit.propTypes = {
  data: PropTypes.shape({
    participants: PropTypes.array,
  }),
  colors: PropTypes.array,
};

export default GameStatsByUnit;
