import React from 'react';
import PortalsUI from './../../portals-ui/react/PortalsUI';
import { CookiesProvider } from 'react-cookie';
import customComponents from './../../components/CustomComponents';

const endpoint =
  process.env.REACT_APP_ENV === 'production'
    ? 'wss://app.meritodynamics.com/ws'
    : process.env.REACT_APP_ENV === 'staging'
    ? 'wss://staging.meritodynamics.com/ws'
    : 'ws://localhost:8080';

// Adding custom type components
PortalsUI.addCustomComponents(customComponents);

const MainPage = props => {
  const { history, location } = props;
  return (
    <CookiesProvider>
      <PortalsUI endpoint={endpoint} history={history} location={location} />
    </CookiesProvider>
  );
};

export default MainPage;
